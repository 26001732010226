import redraw from './redraw.js'

export default {
  namespaced: true, 
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
	 redraw 
  }
}