import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'
import baseLayouts from '../layouts/baseLayouts.vue'
// import beforEach from './beforEach.js'
import app from '@/model/app.js'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'logoShow',
		component: baseLayouts,
		redirect: '/index',
		children: [
			{
				path: '/index',
				name: 'index',
				component: HomeView
			},
			{
				path: '/download',
				name: 'download',
				component: () => import('@/views/download.vue')
			},
			{
				path: '/wecome',
				name: 'home',
				component: () => import('@/views/index.vue')
			},
			{
				path: '/works',
				name: 'works',
				component: () => import('@/views/works.vue')
			},
			{
				path: '/serve',
				name: 'serve',
				component: () => import('@/views/serve.vue')
			},
			{
				path: '/search',
				name: 'search',
				component: () => import('@/views/search.vue')
			},
			
			{
				path: '/MoAI',
				name: 'MoAI',
				component: () => import ('@/views/MoAI/MoAI.vue'),
				redirect: '/MoAI/create',
				children: [
					{
						path: '/MoAI/create',
						name: 'MoAICreate',
						component: () => import ('@/views/MoAI/create/index.vue')
						// component: () => import ('@/views/MoAI2/create.vue')
					},
					{
						path: '/MoAI/list',
						name: 'MoAIList',
						component: () => import ('@/views/MoAI/list/list.vue'),
					},
					{
						path: '/MoAI/redraw',
						name: 'MoAIRedraw',
						component: () => import ('@/views/MoAI/redraw/redraw.vue'),
					},
					{
						path: '/MoAI/my',
						name: 'MoAIMy',
						component: () => import ('@/views/MoAI/my/my.vue'),
						meta: { loginState: true }
					},
					{
						path: '/MoAI/create3D',
						name: 'MoAICreate3D',
						component: () => import ('@/views/MoAI/create3D/index.vue'),
						meta: { loginState: true }
					}
				]
				
			},
			{
				path: '/Hab/index',
				name: 'Hab',
				component: () => import('@/views/Hab/index.vue'),
			},
			{
				path: '/plan',
				name: 'playPlan',
				component: () => import('@/views/pay/payPlan.vue'),
			},
			{
				path: '/mogine',
				name: 'mogineLayout',
				component: () => import('@/views/mogine/layouts.vue'),
				redirect: '/mogine/index',
				meta: { hideSearch: true },
				children: [
					{
						path: '/mogine/index',
						name: 'mogine',
						component: () => import ('@/views/mogine/index.vue')
					},
					{
						path: '/mogine/detail',
						name: 'modelInfo',
						component: () => import ('@/views/mogine/detail.vue')
					},
					{
						path: '/mogine/pay',
						name: 'pay',
						component: () => import ('@/views/pay/point_vip_page.vue')
					},
					{
						path: '/mogine/userCenter',
						name: 'mogineUserCenter',
						component: () => import ('@/views/mogine/userCenter.vue'),
						redirect: '/mogine/userCenter/myRecurso',
						children: [
							{
								path: '/mogine/usercenter/myRecurso',
								name: 'mogineMyRecurso',
								component: () => import('@/views/mogine/myRecurso.vue'),
								meta: { loginState: true }
							},
							{
								path: '/mogine/usercenter/myOrder',
								name: 'mogineMyOrder',
								component: () => import('@/views/mogine/myOrder.vue'),
								meta: { loginState: true }
							},
							{
								path: '/mogine/usercenter/shopCart',
								name: 'mogineShopCart',
								component: () => import('@/views/mogine/shopCart.vue'),
								meta: { loginState: true }
							},
							{
								path: '/mogine/usercenter/notice',
								name: 'mogineNotice',
								component: () => import('@/views/mogine/notice.vue'),
								meta: { loginState: true }
							}
						]
					},
					{
						path: '/mogine/coll',
						name: 'mogineColl',
						component: () => import('@/views/mogine/coll/coll_index.vue'),
						redirect: '/mogine/coll/like',
						children: [
							{
								path: '/mogine/coll/like',
								name: 'mogineLike',
								component: () => import('@/views/mogine/coll/like.vue'),
								meta: { loginState: true }
							},
							{
								path: '/mogine/coll/favorites',
								name: 'mogineFavorites',
								component: () => import('@/views/mogine/coll/favorites.vue'),
								meta: { loginState: true }
							}
						]
					}
				]
			},
			{
				path: '/product',
				name: 'product',
				component: () => import('@/layouts/product.vue'),
				redirect: '/product/index',
				children: [
					{
						path: '/product/index',
						name: 'productIndex',
						component: () => import('@/views/product/product.vue'),
						meta: { productHeader: true, opacityHeader: true },
					},
					{
						path: '/product/example',
						name: 'example',
						component: () => import('@/views/product/example.vue'),
						meta: { productHeader: true, opacityHeader: true },
					},
					{
						path: '/product/aboutAs',
						name: 'aboutAs',
						component: () => import('@/views/product/aboutAs.vue'),
						meta: { opacityHeader: true, productHeader: true }
					},
					{
						path: '/product/page/:type',
						name: 'productPage',
						component: () => import('@/views/product/page.vue'),
					},
				]
			},
			{
				path: '/lab/index',
				name: 'lab',
				component: () => import ('@/views/lab/index.vue'),
				meta: {productHeader: true, showRightNav: false }
			},
			// {
			// 	path: '/lab/list',
			// 	name: 'lab',
			// 	component: () => import ('@/views/lab/list.vue'),
			// 	meta: {productHeader: true, showRightNav: false }
			// },
			{
				path: 'lab/create',
				name: 'create',
				component: () => import ('@/views/lab/create.vue'),
				meta: {productHeader: true, showRightNav: false }
				
			},
			{
				path: '/login',
				name: 'login',
				component: () => import ('@/views/login.vue'),
				meta: {productHeader: true, showRightNav: false }
			},
			{
				path: '/privacy',
				name: 'privacy',
				component: () => import('@/views/agreement/privacy.vue'),
			},
			{
				path: '/agreement',
				name: 'agreement',
				component: () => import('@/views/agreement/agreement.vue'),
			},
			{
				path: '/digitalHuman',
				name: 'digitalHuman',
				component: () => import('@/views/aiHuman/aiHuman.vue')
			}
		],
		
	},
	// {
	// 	path: '/wxLogin',
	// 	name: 'wxLogin',
	// 	component: () => import('@/views/wxLogin.vue')
	// },
	{
		path: '*', redirect: '/index', hidden: true
	}
]
const router = new VueRouter({ routes })

let historyArr = []
const baseBack = router.back
router.back = function () {
	console.log(historyArr.length)
	if (historyArr.length > 1) {
		try{
			router.go(-1)
		}catch{
			router.replace('/')
		}
		
	} else {
		router.replace('/')
	}
}



router.beforeEach((to, from, next) => {
	historyArr.push(to.fullPath)
	if (historyArr.length > 10) historyArr.shift()
	console.log(to, from)
	if (to.name === 'login') {
		let redirect = '/'
		if (from.name) {
			redirect = encodeURIComponent(from.fullPath) 
			to.query.redirect = redirect
			// next({ path: to.path, query: to.query })
		}
		 // else {
			next()
		// }
	} else {
		if (to.meta && to.meta.loginState && !app.user.loginStatus) {
			app.user.autoLogin().then(() => {
				if ( app.user.loginStatus ) {
					next()
				} else {
					Vue.prototype.$message.error('请先登录')
					next({ path: '/login' })
				}
			})
			
		} else {
			next()
		}
	}
	
})

export default router
