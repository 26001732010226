export default {
  namespaced: true, 
  state: {
	  baseImage: null,
	  redrawImage: null,
	  showContrast: false,
	  maskChange: false,
	  showWork: false
  },
  getters: {
	  
  },
  mutations: {
	setBaseImage(state, image) {
		 state.baseImage = image
	},
	setRedrawImage(state, image) {
		state.redrawImage = image
	},
	setShowContrast(state, flag) {
		state.showContrast = flag
	},
	setMaskChange(state, flag) {
		state.maskChange = flag
	},
	setWorkChange(state, flag) {
		state.showWork = flag
	}
  },
  actions: {
	  
  },
  modules: {
  }
}