<template>
	<transition name="message-list">
		<transition-group name="message-model":duration="1000" class="m-message-list" v-if="list.length" tag="div">
			<div class="m-message-model m-default-message" v-for="(item, index) in list" :key="item.index">
				<img src="@/assets/message/default.png" alt="" class="type_icon">
				<div class="msg_text">免费版用户无法使用该功能，点击前往<span class="linkStyle" @click="onLink">开通会员</span>开通</div>
			</div>
		</transition-group>
	</transition>
	
		<!-- :to="{ path: '/mogine/pay', query: { type: 'vip' } }" -->
</template>

<script>
	import router from '@/router/'
	export default {
		name: 'vipMessage',
		data: () => ({
			message: null,
			type: null,
			list: []
		}),
		computed: {
		},
		methods: {
			show(time) {
				console.log(router)
				let messageItem = { index: new Date().getTime() + this.list.length}
				this.list.push(messageItem)
				let timer = setTimeout(() => {
					this.list.splice(this.list.find(item => item === messageItem), 1 )
				}, time || 10000)
			},
			onLink() {
				router.push('/mogine/pay?type=vip')
			}
		}
	}
</script>

<style scoped lang="less">
	.m-message-list{
		position: fixed;
		z-index: 9999;
		top: 10%;
		left: 50%;
		transform: translateX(-50%);
		min-width: 300px;
		max-width: 600px;
		line-height: 32px;
		font-size: 16px;
	}
	.m-message-model{
		padding: 16px 24px;
		min-width: 300px;
		max-width: 600px;
		padding-left: 68px;
		color: #fff;
		margin-bottom: 15px;
		transition: all 1s;
		position: relative;
		&.m-success-message{
			background: linear-gradient(90deg, #33CD7A 13.79%, #33CDBB 87.47%);
		}
		&.m-warn-message{
			background: linear-gradient(90deg, #FBB21a 13.79%, #FBB231 87.47%);
		}
		&.m-error-message{
			background: linear-gradient(90deg, #F04C01 13.79%, #F04C27 87.47%);
		}
		&.m-default-message{
			background: linear-gradient(90deg, #2FB1dd 13.79%, #2FB1FF 87.47%);
		}
		.type_icon{
			width: 32px;
			height: 32px;
			display: block;
			position: absolute;
			top: 16px;
			left: 24px;
		}
		.msg_text{
			word-wrap: normal;
			word-break: break-all;
			white-space: pre-wrap;
		}
		
	}
	// @keyframes message-model {
	// 	0% {
	// 		// transform: rotate3d(90deg,90deg,0)
	// 		// transform: rotateX(85deg)
	// 		opacity: 0;
	// 		zoom: 1;
	// 	}
	// 	50% {
	// 		// transform: rotate3d(90deg, 0, 0);
	// 		opacity: .5;
	// 		zoom: 1.1;
	// 	}
	// 	100%{
	// 		// transform: rotateX(0deg)
	// 		// transform: rotate3d(0,0,0);
	// 		opacity: 1;
	// 		zoom: 1;
	// 	}
	// }
	.message-list-enter-active,.message-list-leave-active{
		transition: opacity 1s;
	}
	.message-list-enter,.message-list-leave-to{
		opacity: 0;
	}
	.message-model-enter-active,.message-model-leave-active{
		position: absolute;
	}
	.message-model-enter,.message-model-leave-to{
		opacity: 0;
	}
	.message-model-move {
	  transition: transform 1s;
	}
	.linkStyle{
		text-decoration: underline;
		color: #2440b3;
		cursor: pointer;
	}
</style>