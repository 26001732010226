import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api/api.js'
import pay from './pay.js'
import route from '@/router/index.js'
import moai from './moai/moai.js'
Vue.use(Vuex)
const CODE_MAX_INTERVAL_TIME = 60

let timer

export default new Vuex.Store({
  state: {
	  // 登录
	  loginVisible: false,
	  // 个人信息
	  userInfoVisible: false,
	  // 获取验证码间隔时间
	  codeInterValTime: 0,
  },
  getters: {
  },
  mutations: {
	  targetLoginVisible(state, visible) {
	  		  if(visible === undefined) visible = !state.loginVisible
	  		  // state.loginVisible = visible
			  route.push('/login')
	  },
	  
	  targetUserInfoVisible(state, visible) {
	  		  if(visible === undefined) visible = !state.userInfoVisible
	  		  state.userInfoVisible = visible
	  },
	  
	  setCodeInterValTime(state, num) {
	  		  state.codeInterValTime = num || CODE_MAX_INTERVAL_TIME
	  		  timer = setInterval(() => {
	  			  if (state.codeInterValTime <= 0) {
	  				  clearInterval(timer)
	  				  return
	  			  }
	  			  state.codeInterValTime --
	  		  }, 1000)
	  },
  },
  actions: {
	  async getVerificationCode({ commit, state }, params) {
	  		  if (state.codeInterValTime) return
	  		  commit('setCodeInterValTime')
	  		  let res = await api.sendCode(params).catch(err => {
	  			  commit('setCodeInterValTime', 1)
	  			  throw err
	  		  })
	  		  return res
	  		  
	  }
  },
  modules: {
	  pay,moai
  }
})
