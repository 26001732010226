import messageBox from './message.vue'
import vipMessage from './vipMessage.vue'
let messageBoxInstance, vipMessageInstance

export default {
	install(Vue) {
		Vue.component(messageBox.name, messageBox)
		Vue.prototype.$message = {
		    warn(message, time) {
		        Vue.prototype.$messageShow({ message, type: "warn" });
		     },
		    success(message, time) {
		        Vue.prototype.$messageShow({ message, type: "success" });
		    },
		    error(message, time) {
		        Vue.prototype.$messageShow({ message, type: "error" });
		    },
		    default(message, time) {
		        Vue.prototype.$messageShow({ message, type: "default" });
		    },
		};
		Vue.prototype.$messageShow = ({ message, type, time }) => {
			let constructor = Vue.extend(messageBox)
			if (!messageBoxInstance) {
				messageBoxInstance = new constructor(constructor).$mount()
				document.body.appendChild(messageBoxInstance.$el)
			}
			
			messageBoxInstance.show(message, type, time)
		}
		Vue.component(vipMessage.name, vipMessage)
		Vue.prototype.vipMessage = (time) => {
			let constructor = Vue.extend(vipMessage)
			if (!vipMessageInstance) {
				vipMessageInstance = new constructor(constructor).$mount()
				document.body.appendChild(vipMessageInstance.$el)
			}
			
			vipMessageInstance.show(time)
		}
	}
}